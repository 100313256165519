/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*THEME XTENSION*/
@font-face {
  font-family: keep-calm;
  src: url(assets/font/keep_calm/KeepCalm-Medium.ttf) format("opentype");
}

body{
  background-color: #F8F9FC;
}